.page-heading {
    height: 70px;
    display: flex;
    align-items: center;
    /* From https://css.glass */
    background: rgba(61, 111, 99, 0.34);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.6px);
    -webkit-backdrop-filter: blur(6.6px);
    border: 1px solid rgba(61, 111, 99, 0.37);

    h1, h2, h3, h4{
        margin-bottom: 0 !important;
    }
}

