// .rdrDateRangePickerWrapper{
//     display: flex !important;
//     flex-direction: column !important;
// }

@media (max-width: 576px) {
    .rdrDateRangePickerWrapper{
        flex-direction: column !important;
        display: flex !important;

    }
}
.reset-link{
    color: reset;
    text-decoration: none;
} 


.dashboard-menu{
    border-radius: 8px;
    border: 3px solid #51796fa4;

    text-decoration: none;
    &:hover{
        background-color: #51796fa4 !important;

    }
}

.dashboard-link{
    text-decoration: none;
}

.modal-60w {
    width: 95%;
    max-width: none!important;
}
@media (min-width: 768px) {
    .modal-60w{
        width: 60%;
        max-width: none!important;
    }
}
.modal-80w {
    width: 80%;
    max-width: none!important;
}

.modal-90w {
    width: 90%;
    max-width: none!important;
}

.modal-95w {
    width: 95%;
    max-width: none!important;
}