
.loader-container {
    height: 100vh;
    margin: 0;
}

.loader-container {
    color: #fff;
    background: #51796f;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 250px;
    }
}



