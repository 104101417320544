.font-14 {
    font-size: 14px;
}
.font-13 {
    font-size: 13px;
}
.font-12 {
    font-size: 12px;
}
.font-11 {
    font-size: 11px;
}
.font-10 {
    font-size: 10px;
}

.font-22{
    font-size: 22px;
}

.font-italic {
    font-style: italic;
}


.btn-xs{
    padding: 3px 6px;
    border-radius: 3px;
    font-size: 12px;
}

.inverse-margin-top-10{
    margin-top: -20px;
}