.team-container {
    max-width: 600px;
    margin: 20px auto;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .team-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .team-selection {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .team-card {
    border: 2px solid transparent;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    transition: transform 0.3s, border-color 0.3s;
  }
  
  .team-card.selected {
    border-color: #007bff;
    transform: scale(1.1);
  }
  
  .team-card img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .team-card p {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .employee-input {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .register-button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .register-button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  .success-message {
    color: green;
    margin-top: 10px;
  }


  .team-list {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  
  .team-column {
    width: 45%;
  }
  
  .team-column h2 {
    text-align: center;
    margin-bottom: 15px;
  }
  
  .team-column ul {
    list-style: none;
    padding: 0;
  }
  
  .team-column li {
    margin: 5px 0;
    padding: 5px 10px;
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
  }