.music-container {
    max-width: 400px;
    margin: 20px auto;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .music-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .music-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .music-card span {
    font-size: 16px;
    font-weight: 500;
  }
  
  .music-card button {
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .music-card button.playing {
    background-color: #dc3545;
  }
  
  .music-card button:hover {
    background-color: #0056b3;
  }